import * as React from 'react';
import MainNavigation from '../Components/MainNavigation';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar/AppBar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Footer from '../Components/Footer';
import { T } from '../Services/Localization';

type Props = {
  children: React.ReactElement;
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
}));

const Wrapper: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  return (
    <>
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h4" color="inherit" noWrap>
            {T('site.title')}
          </Typography>
          <MainNavigation />
        </Toolbar>
      </AppBar>
      <main className={classes.root}>{props.children}</main>
      <Footer />
    </>
  );
};

export default Wrapper;
