import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const theme = createMuiTheme({
  // #212121

  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    type: 'dark',
    primary: {
      light: '#f05545',
      // main: '#BA1B1D',
      // main: '#b71c1c',
      main: '#494949',
      dark: '#7f0000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#a4a4a4',
      main: '#757575',
      dark: '#494949',
      contrastText: '#fff',
    },
    background: {
      paper: '#757575',
      // default: '#564D4A',
      // default: '#494949',
      default: '#494949',
    },
  },
});

export default theme;
