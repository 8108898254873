import { T } from '../Services/Localization';

export const BOX_TYPES = [
  {
    id: 1,
    title: 'LP',
  },
  {
    id: 2,
    title: '2LP',
  },
  {
    id: 3,
    title: '3LP',
  },
  {
    id: 8,
    title: 'Picture',
  },
  {
    id: 4,
    title: 'Box',
  },
  {
    id: 5,
    title: 'EP',
  },
  {
    id: 6,
    title: 'Maxi single 12" 45',
  },
  {
    id: 7,
    title: 'Single 7" 45',
  },
];

export const INSIDE_TYPES = [
  {
    id: 1,
    title: 'GF',
  },
  {
    id: 2,
    title: 'OIS',
  },
  {
    id: 3,
    title: 'Poster',
  },
  {
    id: 4,
    title: '2Posters',
  },
  {
    id: 8,
    title: 'Postcard',
  },
  {
    id: 5,
    title: 'Book',
  },
  {
    id: 6,
    title: 'Sticker',
  },
  {
    id: 7,
    title: 'OBI',
  },
];

export const MAIN_GENRES = [
  {
    name: 'disco',
    title: 'Disco',
    id: 2,
  },
  {
    name: 'pop',
    title: 'Pop',
    id: 4,
  },
  {
    name: 'electronic',
    title: 'Electronic',
    id: 3,
  },
  {
    name: 'rock',
    title: 'Rock',
    id: 1,
  },
];

export const CATALOG_GROUPS = [
  {
    name: 'new',
    title: T('catalog.newItems'),
  },
  ...MAIN_GENRES,
];
