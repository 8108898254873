import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      margin: theme.spacing(2),
    },
  })
);

const Loader: React.FC = () => {
  const classes = useStyles();

  return (
    <div>
      <CircularProgress className={classes.progress} />
      {/*<CircularProgress className={classes.progress} color="secondary" />*/}
    </div>
  );
};

export default Loader;
