import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { T } from '../Services/Localization';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
}));

interface Props {}

const Footer: React.FC<Props> = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Typography
        variant="subtitle1"
        align="center"
        color="textSecondary"
        component="p">
        ヽ(•‿•)ノ
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        Created by{' '}
        <Link color="inherit" href="mailto:musone3@gmail.com">
          artem
        </Link>
      </Typography>
      <Copyright />
    </footer>
  );
};

export default Footer;

const Copyright: React.FC = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'© '}
      {T('site.title')} {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};
