const config = {
  apiKey: 'AIzaSyAMMPFrcQLCrNT4_bhjGPfpMbVfzqGMtzo',
  authDomain: 'musone-club.firebaseapp.com',
  databaseURL: 'https://musone-club.firebaseio.com',
  projectId: 'musone-club',
  storageBucket: 'musone-club.appspot.com',
  messagingSenderId: '346749036965',
  appId: '1:346749036965:web:92f28a47adf7c3ee63204c',
};

export default config;
