import React from 'react';
import { Routes } from '../types';
import { T } from '../Services/Localization';

const routes: Routes = {
  goods: {
    name: 'goods',
    title: 'Goods',
    path: '/goods',
    component: React.lazy(() => import('../Containers/Goods')),
  },
  collections: {
    name: 'collections',
    title: T('site.navigation.collections'),
    path: '/goods',
    component: React.lazy(() => import('../Containers/Goods')),
  },
  info: {
    name: 'info',
    title: T('site.navigation.info'),
    path: '/info',
    component: React.lazy(() => import('../Containers/Info')),
  },
  contacts: {
    name: 'contacts',
    title: T('site.navigation.contacts'),
    path: '/contacts',
    component: React.lazy(() => import('../Containers/Info')),
  },
  admin: {
    name: 'admin',
    title: T('site.navigation.admin'),
    path: '/admin',
    component: React.lazy(() => import('../Containers/Admin/AdminFirestore')),
  },
  home: {
    name: 'home',
    title: T('site.navigation.catalog'),
    path: '/',
    component: React.lazy(() => import('../Containers/Home')),
    // component: Home,
  },
  catalog: {
    name: 'catalog',
    title: T('site.navigation.catalog'),
    path: '/:selectedGroup',
    component: React.lazy(() => import('../Containers/Home')),
  },
};

export default routes;
