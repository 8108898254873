import React from 'react';
import routes from '../configs/router';
import { NavLink } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import { WithStyles } from '@material-ui/core';

// Routing&Menu config
const menuItems: string[] = [
  routes.home.name,
  // routes.collections.name,
  routes.info.name,
  // routes.contacts.name,
  // routes.goods.name,
  // routes.admin.name,
];
// END Routing&Menu config

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: theme.spacing(2),
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
  activeLink: {
    '& button': {
      color: theme.palette.secondary.light,
    },
  },
}));

interface Props extends WithStyles {}

const MainNavigation: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      {menuItems.map(routeName => (
        <NavLink
          key={routes[routeName].name}
          activeClassName={classes.activeLink}
          className={classes.link}
          to={routes[routeName].path}>
          <Button className={classes.menuButton}>
            {routes[routeName].title}
          </Button>
        </NavLink>
      ))}
    </>
  );
};

export default MainNavigation;
