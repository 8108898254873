import React, { Suspense } from 'react';
import * as firebase from 'firebase/app';
import { ThemeProvider } from '@material-ui/styles';
import 'firebase/firestore';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import config from './configs/app';
import routes from './configs/router';
import CssBaseline from '@material-ui/core/CssBaseline';
import Wrapper from './Containers/Wrapper';
import theme from './configs/theme.dark';
import Loader from './Components/Loader';

// Initialize Cloud Firestore through Firebase
const firebaseApp = firebase.initializeApp({
  apiKey: config.apiKey,
  authDomain: config.authDomain,
  projectId: config.projectId,
});

const App: React.FC = () => {
  return (
    <Router>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loader />}>
          <Wrapper>
            <Switch>
              {Object.keys(routes).map(routeName => (
                <Route
                  key={routes[routeName].name}
                  path={routes[routeName].path}
                  exact
                  component={routes[routeName].component}
                />
              ))}
            </Switch>
          </Wrapper>
        </Suspense>
      </ThemeProvider>
    </Router>
  );
};

export default App;
