// const ua = {
//   'site.title' : 'Musone.club',
// };
import { Link } from 'react-router-dom';
import React from 'react';

// Виниловые пластинки для Вашей коллекции

const ru = {
  'site.title': 'Musone.club',

  'site.navigation.info': 'Информация',
  'site.navigation.catalog': 'Каталог',
  'site.navigation.contacts': 'Контакты',
  'site.navigation.collections': 'Коллекции',
  'site.navigation.admin': 'Админ Панель',

  'catalog.newItems': 'Новинки',
  'catalog.seeAll': 'Перейти',

  // 'site.currency.uah': 'грн.',
  'site.currency.uah': '₴',
  'placeholder.search': 'Поиск по исполнителю…',

  // Admin
  'product.isAvailable': 'Есть в наличии',
};
// const en = {
//   'site.title' : 'Musone.club',
//   'placeholder.search' : 'Search…'
// };

type Locales = 'ru';
// 'ua' | 'ru' | 'en';

interface Dict {
  // ua: typeof ua;
  ru: typeof ru;
  // en: typeof en;
}

const dict: Dict = {
  // ua,
  ru,
  // en,
};

interface Params {
  [key: string]: string | number;
}

type Key = keyof typeof ru;
// | keyof typeof ua | keyof typeof en;

export const T = (key: Key, params: Params = {}, locale: Locales = 'ru') => {
  return dict[locale][key] || key;
};
