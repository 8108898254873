import {
  combineReducers,
  configureStore,
  createAction,
  createSlice,
} from 'redux-starter-kit';
import { BOX_TYPES, CATALOG_GROUPS, INSIDE_TYPES } from './data';

const increment = createAction('INCREMENT');
const decrement = createAction('DECREMENT');

// const counter = createReducer(0, {
//   [increment.type]: state => state + 1,
//   [decrement.type]: state => state - 1,
// });

export const counterSlice = createSlice({
  name: 'counter',
  initialState: 0,
  reducers: {
    increment: state => state + 1,
    decrement: state => state - 1,
  },
});

interface StoreConfigState {
  products: {
    boxTypes: Record<'id' | 'title', string | number>[];
    insideTypes: Record<'id' | 'title', string | number>[];
  };
  catalog: {
    groups: { name: string; title: string }[];
  };
}

const initialStoreConfig: StoreConfigState = {
  products: {
    boxTypes: BOX_TYPES,
    insideTypes: INSIDE_TYPES,
  },
  catalog: {
    groups: CATALOG_GROUPS,
  },
};

const storeConfig = createSlice({
  name: 'storeConfig',
  initialState: initialStoreConfig,
  reducers: {},
});

const rootReducer = combineReducers({
  counter: counterSlice.reducer,
  storeConfig: storeConfig.reducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default store;
